import React from "react"
import { Link } from "gatsby"
import { use100vh } from "react-div-100vh"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { SiteMetaConfig } from "../config/config-data"
import "../styles/global.css"


const ThankYouPage = ({ location }) => (

  <>
    <GatsbySeo
      title={`Thank You | ${SiteMetaConfig.site_name}`}
      description={SiteMetaConfig.site_description}
      canonical={`${SiteMetaConfig.site_url}${location.pathname}`}
      openGraph={{
        type: "website",
        url: `${SiteMetaConfig.site_url}${location.pathname}`,
        title: `Thank You | ${SiteMetaConfig.site_name}`,
        description: SiteMetaConfig.site_description,
        images: [
          { url: SiteMetaConfig.site_image },
        ],
        site_name: SiteMetaConfig.site_name,
      }}
      twitter={{
        handle: `@${SiteMetaConfig.site_twitter_handle}`,
        site: `@${SiteMetaConfig.site_name}`,
        cardType: "summary_large_image",
      }}
      facebook={{
        appId: SiteMetaConfig.site_facebook_app_id,
      }}
      htmlAttributes={{
        lang: SiteMetaConfig.site_language,
      }}
      noindex={true}
      nofollow={true}
    />
    <div id="thankyou_1662797258167" style={{minHeight: use100vh(), display: "grid", alignItems: "center"}}>
      <div id="thankyou_1662797259438" className="Card Card_Container">
        <div id="thankyou_1662797261199" className="Sub_Heading_Text">Thank You For Submitting The Form</div>
        <div id="thankyou_1662797262534" className="Normal_Text">We have received your details and will contact you shortly. Thank you for your patience.</div><div id="thankyou_1662797281535" className="Extra_Spacing" />
        <div id="thankyou_1662797264022" className="Normal_Text">In the meantime feel free to have a look at our website, you might find something useful.</div><div id="thankyou_1662797282798" className="Extra_Spacing" />
        <Link id="thankyou_1662797265263" to="/"><button id="thankyou_1662797284957">Click Here</button></Link>
      </div>
    </div>
  </>
)

export default ThankYouPage